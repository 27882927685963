// export const  api  = "https://api.studio.thegraph.com/proxy/34689/onvault-main/0.0.1/"
export const api =
  "https://api.studio.thegraph.com/query/16941/onvault-subgraph-wbtc-2/version/latest";
export const apiBsc =
  "https://api.studio.thegraph.com/query/16941/onvault-wbtc-bsc/version/latest";
export const link1 = "https://sepolia.etherscan.io/tx";
export const lendingApiSepolia =
  "https://api.studio.thegraph.com/query/50876/lendingborrowingsepolia/version/latest";

export const lendingApiBsc =
  "https://api.studio.thegraph.com/query/50876/lendingborrowingbsc/version/latest";
