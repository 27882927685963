import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import eth from "../../../../assets/img/eth.svg";
import usdao from "../../../../assets/img/usdao.svg";
import usdao_light from "../../../../assets/img/usdao_light.svg";
import wbtc from "../../../../assets/img/wbtcLight.png";
import wbtcD from "../../../../assets/img/wbtcPng.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { truncateToDecimals } from "../../../common/utils";
import {
  collateralFn,
  liquidationReserve,
  liquidationPriceFn,
  feeFn,
  collateralFnForDeposite,
  getMax,
  totalDeptFn,
  confirmDepositeFn,
} from "../helper";
import { ethers } from "ethers";
import { getAccount } from "@wagmi/core";
import swal from "sweetalert";
import { getAllowance, getMetaMask } from "../../../blockchain/commonFunction";
import { setTransactionLoader } from "../../../redux/action";

var cn = require("classnames");

const initialDepositeVal = {
  etherVal: "",
  usdaoVal: "",
};

const initialCalVal = {
  liquidationPrice: 0,
  fee: 0,
  totalDept: 0,
  collateralRatio: 0,
};

const DepositeInfo = ({ depositInputVal, calcualtion }) => {
  return (
    <div className="deposite-info">
      <div className="d-flex justify-content-between deposite-detail-wrap">
        <p>Deposit</p>
        <p>{truncateToDecimals(depositInputVal.etherVal, 6) || "0.00"} WBTC</p>
      </div>

      <div className="d-flex justify-content-between deposite-detail-wrap">
        <p>
          Liquidation reserve
          {["top"].map((placement) => (
            <OverlayTrigger
              key={placement}
              placement={placement}
              overlay={
                <Tooltip id={`tooltip-${placement}`}>
                  200 USDAO reserve will be refunded/burnt once vault is closed
                  the owner
                </Tooltip>
              }
            >
              <button className="btn pt-0 pb-0 ps-2 pe-2">
                <FontAwesomeIcon className="tooltipIcon" icon={faCircleInfo} />
              </button>
            </OverlayTrigger>
          ))}
        </p>
        <p>{liquidationReserve} USDAO</p>
      </div>

      <div className="d-flex justify-content-between deposite-detail-wrap">
        <p>Fee</p>

        <p>{truncateToDecimals(calcualtion.fee, 6)} USDAO (0.50%)</p>
      </div>

      <div className="d-flex justify-content-between deposite-detail-wrap">
        <p>Total debt</p>
        <p>{truncateToDecimals(calcualtion.totalDept)} USDAO</p>
      </div>

      <div className="d-flex justify-content-between deposite-detail-wrap">
        <p>Receive</p>
        <p>{truncateToDecimals(depositInputVal.usdaoVal, 6)} USDAO</p>
      </div>

      <div className="d-flex justify-content-between deposite-detail-wrap">
        <p>Collateral ratio</p>
        <p>{truncateToDecimals(calcualtion.collateralRatio, 6)} %</p>
      </div>
    </div>
  );
};

const Deposite = ({
  accountDetails = {},
  setRatio,
  refreshAllData,
  vaultDetails = {},
  ratio,
}) => {
  const [depositInputVal, setDepositInputVal] = useState(initialDepositeVal);
  const [error, setError] = useState({});
  const [calcualtion, setCalculation] = useState(initialCalVal);
  const [disableBtn, setDisableBtn] = useState(false);
  const [disable, setDisable] = useState(false);
  const selector = useSelector((state) => state);
  const dispatch = useDispatch();

  let { theme = {} } = selector.themeReducer;

  const maxHandler = async (type) => {
    if (type === "wbtc") {
      const maxEther =
        accountDetails && accountDetails.etherBalance
          ? accountDetails.etherBalance
          : "0.00";
      setDepositInputVal((state) => ({
        ...state,
        usdaoVal: "100",
        etherVal: maxEther,
      }));
    } else {
      const maxUsdao = await getMax(depositInputVal.etherVal);
      let val = maxUsdao.split(".");
      setDepositInputVal((state) => ({
        ...state,
        usdaoVal: val[0].toString(),
      }));
    }
  };

  document.onkeydown = function (e) {
    if (e.defaultPrevented) {
      return;
    }
    if (e.keyCode === 109 || e.keyCode === 189) {
      return false;
    }
  };

  const depositChangeHandler = (e, type) => {
    let ethVal, usdaoVal;
    if (type === "wbtc") {
      ethVal = e.target.value;
      setDepositInputVal((state) => ({ ...state, etherVal: ethVal }));
      !!ethVal &&
        setDepositInputVal((state) => ({ ...state, usdaoVal: "100" }));
    } else {
      usdaoVal = e.target.value;
      setDepositInputVal((state) => ({ ...state, usdaoVal: usdaoVal }));
    }
  };

  useEffect(() => {
    (async function () {
      let fee = await feeFn(depositInputVal);
      let usdaoVal = ethers.utils
        .parseUnits(depositInputVal.usdaoVal, 18)
        .add(ethers.utils.parseUnits("200", 18))
        .add(fee);
      let collateral = await collateralFnForDeposite(
        depositInputVal.etherVal,
        usdaoVal
      );
      let liquidationPrice = await liquidationPriceFn(depositInputVal.etherVal);

      let totalDept = await totalDeptFn(depositInputVal.usdaoVal);
      setRatio(collateral);
      if (parseFloat(collateral) < 110) {
        delete error["usdaoValErr"];
        setError({
          ratioMsg: vaultDetails.recoveryModeCheck
            ? "System is in recovery Mode, Vault cannot be opened below 125% collateral ratio. 0% fee will be applied on creating new Vault during Recovery Mode."
            : "Vault cannot be opened below 110% collateral ratio",
        });
      } else if (parseFloat(depositInputVal.usdaoVal) < 100) {
        delete error["ratioMsg"];
        setError({
          ...error,
          usdaoValErr: "Borrow limit cannot be below 100 USDAO",
        });
      } else {
        delete error["ratioMsg"];
        delete error["usdaoValErr"];
        setError({ ...error });
      }
      setCalculation({
        liquidationPrice: liquidationPrice,
        fee: ethers.utils.formatUnits(fee, 18),
        totalDept: totalDept,
        collateralRatio: collateral,
      });
    })();
  }, [depositInputVal]);

  useEffect(() => {
    if (Object.keys(error).length) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [error]);

  const confirmDepositeClick = async () => {
    let { address } = getAccount();
    let provider = await getMetaMask();
    if (vaultDetails.recoveryModeCheck && parseFloat(ratio) < 125) {
      setDisable(false);
      swal(
        "Recovery Mode: User can not open Vault below 125% in the Recovery Mode."
      );
      return;
    }
    if (depositInputVal.etherVal > parseFloat(accountDetails.etherBalance)) {
      setDisable(false);
      swal("Insufficient funds");
      return;
    }
    setDisable(true);
    dispatch(setTransactionLoader(true));
    const isAllowance = await getAllowance(
      address,
      ethers.utils.parseUnits(depositInputVal.etherVal, 18)
    );
    console.log("deposit Input value=>", depositInputVal);
    if (isAllowance) {
      provider
        .waitForTransaction(isAllowance.hash)
        .then(async () => {
          await confirmDepositeFn(
            depositInputVal,
            refreshAllData,
            setDisableBtn,
            setDisable,
            accountDetails.etherBalance
          );
          dispatch(setTransactionLoader(false));
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      dispatch(setTransactionLoader(false));
      refreshAllData();
    }
  };

  return (
    <>
      <div className="tab-box-wrap">
        <div className="tab-box-info d-flex justify-content-between align-items-center">
          <p>Deposit</p>
          <p>
            Wallet:{" "}
            {accountDetails && accountDetails.etherBalance
              ? truncateToDecimals(accountDetails.etherBalance, 6)
              : "0.00"}
          </p>
        </div>

        <div className="tab-box-values d-flex justify-content-between align-items-center">
          <input
            type="number"
            id="number"
            className="inputNumber"
            style={{ color: theme === "dark-theme" ? "#fff" : "#9EA0A4" }}
            placeholder="Enter amount"
            value={depositInputVal.etherVal}
            min="0.01"
            step="0.01"
            onChange={(e) => depositChangeHandler(e, "wbtc")}
            onWheel={() => document.activeElement.blur()}
          />
          <div className="d-flex">
            <div className="value-status" onClick={() => maxHandler("wbtc")}>
              <p>MAX </p>
            </div>
            {/* <img
              className="wbtcimg"
              src={theme === "dark-theme" ? wbtc : wbtcD}
              alt=""
            /> */}
          </div>
        </div>
      </div>
      <div
        className={cn("tab-box-wrap", !depositInputVal.etherVal && "disabled")}
      >
        <div className="tab-box-info d-flex justify-content-between align-items-center">
          <p>Borrow</p>
          <p>
            Available:{" "}
            {accountDetails && accountDetails.usmBalance
              ? truncateToDecimals(accountDetails.usmBalance)
              : "0.00"}
          </p>
        </div>

        <div className="tab-box-values d-flex justify-content-between align-items-center ">
          <input
            type="number"
            id="number"
            className="inputNumber"
            style={{ color: theme === "dark-theme" ? "#fff" : "#9EA0A4" }}
            placeholder="Enter amount"
            value={depositInputVal.usdaoVal}
            min="0.01"
            step="0.01"
            onChange={(e) => depositChangeHandler(e, "usdao")}
            onWheel={() => document.activeElement.blur()}
          />
          <div className="d-flex">
            <div className="value-status" onClick={() => maxHandler("usdao")}>
              <p>MAX</p>
            </div>
            <img src={theme === "dark-theme" ? usdao : usdao_light} alt="" />
          </div>
        </div>
      </div>
      {error.usdaoValErr && <div className="error">{error.usdaoValErr}</div>}
      {error.ratioMsg && <div className="error">{error.ratioMsg}</div>}
      <div className="d-flex justify-content-center mb-5">
        <button
          rel="noreferrer"
          className={
            disableBtn ||
            !parseFloat(depositInputVal.usdaoVal) ||
            !parseFloat(depositInputVal.etherVal) ||
            disable
              ? "menu-link btn btn-gradient disabled"
              : "menu-link btn btn-gradient"
          }
          // className={cn(
          //   "menu-link btn btn-gradient",
          //   (disableBtn ||
          //     !parseFloat(depositInputVal.usdaoVal) ||
          //     !parseFloat(depositInputVal.etherVal) ||
          //     disable) &&
          //     "disabled"
          // )}
          onClick={confirmDepositeClick}
        >
          Confirm
        </button>
      </div>
      {depositInputVal.etherVal && depositInputVal.usdaoVal && (
        <DepositeInfo
          depositInputVal={depositInputVal}
          calcualtion={calcualtion}
        />
      )}
    </>
  );
};

export default Deposite;
