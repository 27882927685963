import { ethers } from "ethers";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import {
  Form,
  InputGroup,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { ThreeDots, Oval } from "react-loader-spinner";
import { truncateToDecimals } from "../../common/utils";
import { liquidateFn, liquidateAll, getLiqData } from "./helper";
import { getTCR } from "../../blockchain/commonFunction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import "./liquidation.scss";

var cn = require("classnames");

const RenderTable = ({
  liqData,
  tcr,
  // totalStakeVal,
  recoveryModeCheck,
  debt,
  disableLiq,
  setDisableLiq,
  liquidationTableData,
  loader,
  setMainLiqBtn,
  setLiquidationTableData,
  dispatch,
}) => {
  const [copyStatus, setCopyStatus] = useState(false);
  const liquidate = async (e, address) => {
    setDisableLiq(true);
    await liquidateFn(
      address,
      setDisableLiq,
      setLiquidationTableData,
      dispatch
    );
  };
  let data =
    liquidationTableData && liquidationTableData.length
      ? liquidationTableData
      : liqData;

  const diableButton = (val) => {
    if (parseFloat(tcr) >= 125 && parseFloat(val.icrRatio) >= 110) {
      return true;
    } else if (parseFloat(tcr) <= 125 && parseFloat(val.icrRatio) >= 125) {
      return true;
    }
    //  else if (
    //   110 <= parseFloat(val.icrRatio) < 125 &&
    //   totalStakeVal < debt &&
    //   recoveryModeCheck
    // )
    // {
    //   return true;
    // }
    else if (parseFloat(val.icrRatio) < 110) {
      setMainLiqBtn(false);
    }
    return false;
  };

  const copyAddress = (address) => {
    if (!navigator.clipboard) {
      return;
    }
    setCopyStatus(true);
    navigator.clipboard.writeText(address);
  };

  return loader ? (
    <tr>
      <td colSpan={8}>
        <div className="spinner">
          <ThreeDots type="" color="#3f9cb5" height="100" width="100" />
        </div>
      </td>
    </tr>
  ) : data && !!data.length ? (
    data.map((val, index) => {
      return (
        <tr key={val.id}>
          <td
            onClick={() => copyAddress(val.id)}
            className="copy-address"
            onMouseMove={() => setCopyStatus(false)}
          >
            {" "}
            {val.id.substring(0, 4)}....
            {val.id.substring(val.id.length - 4)}
            {["bottom"].map((placement) => (
              <OverlayTrigger
                key={placement}
                placement={placement}
                overlay={
                  <Tooltip id={`tooltip-${placement}`}>
                    {copyStatus ? "Copied" : "Copy"}
                  </Tooltip>
                }
              >
                <button className="btn p-0 ms-2">
                  <FontAwesomeIcon icon={faCircleInfo} />
                </button>
              </OverlayTrigger>
            ))}
          </td>
          <td>{truncateToDecimals(ethers.utils.formatUnits(val.coll, 18))}</td>
          <td>{truncateToDecimals(ethers.utils.formatUnits(val.debt, 18))}</td>

          <td className="ratio-loader">
            {val.icrRatio ? (
              `${truncateToDecimals(val.icrRatio)}%`
            ) : (
              <Oval color="#3f9cb5" height={20} width={20} />
            )}
          </td>
          <td className="text-end">
            <button
              className={cn(
                "btn btn-outline",
                (diableButton(val) || disableLiq) && "disabled"
              )}
              onClick={(e) => liquidate(e, val.id)}
            >
              Liquidate
            </button>
          </td>
        </tr>
      );
    })
  ) : (
    <tr>
      <td colSpan={8}>
        <div className="no-data">No Data Found</div>
      </td>
    </tr>
  );
};

const LiquidationTable = ({
  liquidationTableData,
  setLiquidationTableData,
  // stakeDetails,
  accountDetails,
  vaultDetails,
  dashboardDetails,
}) => {
  const dispatch = useDispatch();

  const [numOfVaults, setNumOfVaults] = useState();
  const [disableLiq, setDisableLiq] = useState(false);
  const [liqData, setLiqData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [mainLiqBtn, setMainLiqBtn] = useState(true);

  let { tcr } = dashboardDetails;
  let { debt, recoveryModeCheck } = vaultDetails;
  // let { totalStakeVal } = stakeDetails;

  useEffect(() => {
    (async function () {
      var number = document.getElementById("number");

      if (number) {
        number.onkeydown = function (e) {
          if (
            !(
              (e.keyCode > 95 && e.keyCode < 106) ||
              (e.keyCode > 47 && e.keyCode < 58) ||
              e.keyCode == 8
            )
          ) {
            return false;
          }
        };
      }

      await getLiqData(setLiqData, setLoader);
    })();
  }, []);

  useEffect(() => {
    if (!!liquidationTableData.length && liquidationTableData.length === 1) {
      setDisableLiq(true);
    }
  }, [liquidationTableData, vaultDetails]);

  const liquidateChangeHandler = (e) => {
    const { value } = e.target;
    setNumOfVaults(value);
  };

  const liquidateAllSubmit = async () => {
    let count = 0;
    liquidationTableData.map((val, index) => {
      if (
        (parseFloat(tcr) > 125 && parseFloat(val.icrRatio) > 110) ||
        (parseFloat(tcr) < 125 && parseFloat(val.icrRatio) > 125)
      ) {
        count++;
      }
    });
    let enabledBtn = liquidationTableData.length - count;
    if (!numOfVaults) {
      return swal("Please enter no. of Vaults to be Liquidated.");
    }
    if (liquidationTableData.length > 1 && Number(numOfVaults) >= enabledBtn) {
      return swal(
        `You can Liquidate only ${enabledBtn - 1} vaults at the moment`
      );
    }

    setDisableLiq(true);
    await liquidateAll(
      numOfVaults,
      setDisableLiq,
      setLiquidationTableData,
      dispatch
    );
  };
  return (
    <div className="_table-contant">
      <Table responsive variant="dark" className="deposit-info-table">
        <thead>
          <tr>
            <th>
              <p>Owner</p>
            </th>
            <th>
              <p>Collateral (RP)</p>
            </th>
            <th>
              <p>Debt (USDAO)</p>
            </th>
            <th>
              <p>Coll Ratio</p>
            </th>
            <th>
              <p>Liquidate</p>
            </th>
          </tr>
        </thead>
        <tbody className="liquidation-table">
          <RenderTable
            setLiqData={setLiqData}
            dispatch={dispatch}
            recoveryModeCheck={recoveryModeCheck}
            // totalStakeVal={totalStakeVal}
            debt={debt}
            loader={loader}
            liquidationTableData={liquidationTableData}
            liqData={liqData}
            tcr={tcr}
            disableLiq={disableLiq}
            setDisableLiq={setDisableLiq}
            setMainLiqBtn={setMainLiqBtn}
            setLiquidationTableData={setLiquidationTableData}
          />
        </tbody>
      </Table>
    </div>
  );
};
export default LiquidationTable;
