import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Content from "./content";
import Layout from "./Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faDiscord,
  faTelegram,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";

import { showClaimFn } from "../../components/Dashboard/helper";
import {
  checkRecovery,
  getVaultDebtColl,
  getVaultStatusFn,
  collateralFn,
} from "../../components/Vault/helper";
import {
  setDashboardDetails,
  setVaultDetails,
  setAccountDetails,
  setTransactionLoader,
} from "../../redux/action";
import {
  sortedVaultSize,
  fetchAllInitialValues,
  getTCR,
  fetchInitialAccVal,
  getAccountDetails,
} from "../../blockchain/commonFunction";
import { useChainId, useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";

export const loadAllData = async (
  setAlertType,
  vaultDetails,
  dashboardDetails,
  accountDetails,
  dispatch,
  setAlertMetaMaskLogOut
) => {
  let ratio;
  let details = await getAccountDetails(setAlertMetaMaskLogOut);
  const vaultSize = await sortedVaultSize();
  let totalSupplyVal = await fetchAllInitialValues();
  const vaultStatus = await getVaultStatusFn();
  const vault = await getVaultDebtColl();
  if (Number(vault.coll) && Number(vault.debt)) {
    ratio = await collateralFn(vault.coll, vault.debt);
  }
  let recoveryCheckVal = await checkRecovery();
  let tcr = await getTCR();
  let showClaimBtn = await showClaimFn();
  let initailVal = await fetchInitialAccVal();
  let dashboardData = Object.assign(totalSupplyVal, { showClaimBtn }, tcr);
  let vaultData = Object.assign(
    vaultSize,
    { vaultStatus },
    vault,
    recoveryCheckVal,
    { ratio }
  );
  let layoutData = Object.assign(initailVal, details);

  dispatch(setDashboardDetails(dashboardData));
  dispatch(setAccountDetails(layoutData));
  dispatch(setVaultDetails(vaultData));
  Promise.all([dashboardDetails, vaultDetails, accountDetails]).then((val) => {
    if (val) {
      setAlertType("");
      dispatch(setTransactionLoader(false));
    }
  });
};
const LayoutWrapComp = () => {
  const { open } = useWeb3Modal();
  let chainId = useChainId();
  const { address } = useAccount();
  const [toggleSidebar, setToggleSidebar] = useState(
    window.innerWidth < 813 ? false : true
  );
  const [alertType, setAlertType] = useState();
  const [alertMetaMaskLogOut, setAlertMetaMaskLogOut] = useState(false);
  const [alertNetworkType, setAlertNetworkType] = useState(false);

  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  let {
    dashboardDetails = {},
    accountDetails = {},
    vaultDetails = {},
  } = selector.appReducer;

  useEffect(() => {
    if (accountDetails.account) {
      setAlertMetaMaskLogOut(true);
    }
  }, []);

  useEffect(() => {
    metaMask();
  }, [address, chainId]);

  const metaMask = async () => {
    if (address === undefined) {
      open();
    }
    let details = await getAccountDetails(setAlertMetaMaskLogOut);
    if (Object.keys(details).length) setAlertMetaMaskLogOut(false);
    if (
      Object.keys(details).length &&
      details.networkName !== "bnbt" &&
      details.networkName !== "sepolia" &&
      details.networkName !== "unknown"
    ) {
      setAlertMetaMaskLogOut(false);
      setAlertNetworkType(true);
    }
    setAlertType("loading");
    await loadAllData(
      setAlertType,
      vaultDetails,
      dashboardDetails,
      accountDetails,
      dispatch,
      setAlertMetaMaskLogOut,
      address,
      chainId
    );
  };

  return (
    <div className="dashboard-screen">
      <Layout
        setToggleSidebar={setToggleSidebar}
        alertNetworkType={alertNetworkType}
        setAlertMetaMaskLogOut={setAlertMetaMaskLogOut}
        alertMetaMaskLogOut={alertMetaMaskLogOut}
        toggleSidebar={toggleSidebar}
        alertType={alertType}
        setAlertType={setAlertType}
        metaMask={metaMask}
      />
      <div
        className={toggleSidebar ? "dashboard-wrap" : "dashboard-wrap active"}
      >
        <Content setAlertType={setAlertType} />
      </div>
      <>
        <div className="sidebar-social-wrap">
          <a
            href="https://twitter.com/usdao_io"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>

          <a
            href="https://discord.gg/JHbXb4kQwG"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faDiscord} />
          </a>

          <a
            href="https://t.me/+cZgxay98TSo1ZmNh"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faTelegram} />
          </a>

          <a
            href="https://github.com/USDAO-Protocol"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faGithub} />
          </a>
        </div>
      </>
    </div>
  );
};

export default LayoutWrapComp;
