import React, { useMemo, useEffect, useState } from "react";
import { Col, Row, OverlayTrigger, Tooltip, Tabs, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

import {
  truncateToDecimals,
  convertToInternationalCurrencySystem,
} from "../../common/utils";

import "react-circular-progressbar/dist/styles.css";
var cn = require("classnames");

const TotalSupply = ({
  onClose,
  closeButtonStatus,
  vaultDetails = {},
  dashboardDetails = {},
  accountDetails = {},
  theme,
  showClose = false,
}) => {
  const [wbtBal, setWbtBal] = useState();
  useMemo(() => {
    setWbtBal(accountDetails.etherBalance);
  }, [accountDetails.etherBalance]);

  useEffect(() => {
    setWbtBal(accountDetails.etherBalance);
  }, [accountDetails.etherBalance]);
  return (
    <div className="vault-card">
      <Row className="gy-4">
        <Col
          sm={!!vaultDetails.vaultStatus && showClose ? 9 : 12}
          lg={!!vaultDetails.vaultStatus && showClose ? 10 : 12}
        >
          <div className="dashboard-card">
            <Row className="gy-4">
              <Col md={3}>
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <p className="card-body-name">USDAO Balance</p>
                  <p className="card-body-value">
                    {dashboardDetails && parseInt(accountDetails.usmBalance) > 1
                      ? accountDetails.usmBalance
                      : "0.00"}{" "}
                  </p>
                  {/* <p className="card-body-value">
                    {dashboardDetails &&
                    !!parseInt(dashboardDetails.totalSupplyUsm) > 1
                      ? convertToInternationalCurrencySystem(
                          dashboardDetails.totalSupplyUsm
                        )
                      : "0.00"}{" "}
                  </p> */}
                </div>
              </Col>

              <Col md={3}>
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <p className="card-body-name tcr-ratio">
                    Total Collateral Ratio
                    {["top"].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            The ratio of Sum of Vaults in USD to the total debt
                            of Vaults in USDAO
                          </Tooltip>
                        }
                      >
                        <button className="btn pt-0 pb-0 ps-2 pe-2">
                          <FontAwesomeIcon
                            style={{
                              color:
                                theme === "dark-theme" ? "#fff" : "#0E7EFE",
                            }}
                            icon={faCircleInfo}
                          />
                        </button>
                      </OverlayTrigger>
                    ))}
                  </p>
                  <p className="card-body-value">
                    {" "}
                    <span></span>{" "}
                    {dashboardDetails && !!dashboardDetails.tcr
                      ? truncateToDecimals(dashboardDetails.tcr)
                      : "0.00"}
                    %
                  </p>
                </div>
              </Col>

              <Col md={3}>
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <p className="card-body-name">RP User Balance</p>
                  <p className="card-body-value">
                    {wbtBal && !!wbtBal && wbtBal.length > 10
                      ? `${truncateToDecimals(wbtBal).substring(
                          0,
                          4
                        )}...${truncateToDecimals(wbtBal).substring(0 - 4)} `
                      : wbtBal !== undefined
                      ? truncateToDecimals(wbtBal)
                      : "0.00"}
                  </p>
                </div>
              </Col>
              <Col md={3}>
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <p className="card-body-name">RP Price</p>
                  <p className="card-body-value">
                    ${" "}
                    {dashboardDetails && !!dashboardDetails.marketPrice
                      ? truncateToDecimals(dashboardDetails.marketPrice)
                      : "0.00"}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>

        {!!vaultDetails.vaultStatus && showClose && (
          <Col sm="3" lg={2} className="p-sm-0 close-des">
            <div
              className={cn("dashboard-card btn-outline h-100 close-btn", {
                disabled: closeButtonStatus,
              })}
              onClick={onClose}
            >
              <div className="d-flex align-items-center justify-content-between flex-column h-100 close-box">
                <svg
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="close"
                    d="M0.753213 17.263C0.530724 17.0635 0.415546 16.8405 0.407679 16.5936C0.399811 16.3468 0.50056 16.1175 0.709942 15.9056L7.58803 8.94409L0.345961 2.45259C0.123473 2.25317 0.00829468 2.03004 0.000427384 1.78323C-0.00743991 1.53643 0.0933088 1.30705 0.302691 1.09513L0.742378 0.650112C0.95176 0.438195 1.18602 0.32849 1.44513 0.320996C1.70426 0.313503 1.94506 0.409464 2.16755 0.608898L9.4096 7.10034L16.0992 0.329539C16.3086 0.117606 16.5429 0.0079005 16.802 0.000407012C17.0611 -0.00708647 17.302 0.0888921 17.5244 0.288325L18.025 0.73703C18.2476 0.936463 18.3627 1.15959 18.3705 1.40639C18.3785 1.65321 18.2776 1.88256 18.0684 2.09448L11.3786 8.86528L18.654 15.3867C18.8766 15.586 18.9918 15.8092 18.9996 16.056C19.0075 16.3029 18.9068 16.5322 18.6974 16.7441L18.2576 17.1892C18.0484 17.4011 17.814 17.5107 17.555 17.5183C17.2957 17.5257 17.0549 17.4298 16.8325 17.2304L9.55711 10.7089L2.67899 17.6705C2.46961 17.8825 2.23535 17.9922 1.97624 17.9996C1.71711 18.0072 1.47631 17.9112 1.25382 17.7118L0.753213 17.263Z"
                    fill="white"
                    fillOpacity="1"
                  />
                </svg>

                <p className="fs-6 text-nowrap close-text">Close Vault</p>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default TotalSupply;
