import React from "react";
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  convertToInternationalCurrencySystem,
  truncateToDecimals,
} from "../../../common/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
// import { Link } from "react-router-dom";

const StatisticsCard = ({ dashboardDetails = {}, vaultDetails = {} }) => {
  return (
    <Col xl={12} md={12} xs={12}>
      <div className="dashboard-card">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="title-white">Statistics</h4>
          {/* <Link to="/vault-history">
              <button className="btn card-btn">Vault History</button>
            </Link> */}
        </div>

        <div className="d-flex align-items-center card-body-row">
          <div className="row w-100">
            <div className="col-3 border-card-details">
              <div className="row">
                <p className="card-body-name mb-2">VAULTS</p>
                <p className="card-body-value">
                  {vaultDetails.vaultSize ? vaultDetails.vaultSize : "0.00"}
                </p>
              </div>
            </div>
            <div className="col-5 border-card-details">
              <div className="row">
                {["top"].map((placement) => (
                  <OverlayTrigger
                    key={placement}
                    placement={placement}
                    overlay={
                      <Tooltip className="tooltip" id={`tooltip-${placement}`}>
                        Total RP in the pool in $
                      </Tooltip>
                    }
                  >
                    <p className="card-body-name mb-2">
                      Total Collateral &nbsp;
                      {window.innerWidth > 400 && (
                        <button className="btn p-0">
                          <FontAwesomeIcon
                            className="text-primary"
                            icon={faCircleInfo}
                            size={20}
                          />
                        </button>
                      )}
                    </p>
                  </OverlayTrigger>
                ))}

                <p className="card-body-value">
                  ${" "}
                  {dashboardDetails.tvlVal
                    ? convertToInternationalCurrencySystem(
                        dashboardDetails.tvlVal
                      )
                    : "0.00"}
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="col">
                <p className="card-body-name mb-2">RP Price</p>
                <p className="card-body-value">
                  {dashboardDetails.marketPrice
                    ? dashboardDetails.marketPrice
                    : "0.00"}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="d-flex align-items-center justify-content-between card-body-row">
                <p className="card-body-name">ETH Supply</p>
                <p className="card-body-value">459k</p>
              </div> */}
      </div>
    </Col>
  );
};

export default StatisticsCard;
